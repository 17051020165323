import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ImageFooterDomeInvalides from "./imageFooterDomeInvalides-en"
import { MdPhone, MdLocationOn } from "react-icons/md"

const FooterBlock = ({ ...props }) => (
  <Footer>
    <FlexWrapper>
      <FlexItem>
        <Title>Contact us</Title>
        <Address>
          <b>Consultation</b>
          <br />
          Espace Médical Vauban
          <br />
          2A Avenue de Ségur
          <br />
          75 007 Paris
          <br />
          <A href="tel:+33153598809">
            <MdPhone
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            (+33) 1 53 59 88 09
          </A>
          <br />
          <Link
            to="/en/contact/"
            css={css`
              text-decoration: none;
            `}
          >
            <MdLocationOn
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            Access map
          </Link>
        </Address>
      </FlexItem>
      <FlexItem>
        <Address
          css={css`
            margin-top: 42px;
            strong,
            b {
              letter-spacing: 0.04em;
            }
            @media (max-width: 678px) {
              margin-top: 25px;
            }
          `}
        >
          <b>Hospitalization</b>
          <br />
          Clinique du sport
          <br />
          36 Boulevard Saint Marcel
          <br />
          75 005 Paris
          <br />
          <A href="tel:+33186867505">
            <MdPhone
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            (+33) 1 86 86 75 05
          </A>
          <br />
          <Link
            to="/en/contact/"
            css={css`
              text-decoration: none;
            `}
          >
            <MdLocationOn
              css={css`
                transform: translateY(1px);
              `}
            />{" "}
            Access map
          </Link>
        </Address>
      </FlexItem>
      <FlexItem>
        <Title
          css={css`
            @media (max-width: 678px) {
              margin-top: 25px;
            }
          `}
        >
          Procedures
        </Title>
        <Ul>
          <Li>
            <Link to="/en/elbow-surgery/">Elbow surgery</Link>
          </Li>
          <Li>
            <Link to="/en/shoulder-surgery/">Shoulder surgery</Link>
          </Li>
          <Li>
            <Link to="/en/hand-surgery/">Hand surgery</Link>
          </Li>
          <Li>
            <Link to="/en/nerves-surgery/">Nerves surgery</Link>
          </Li>
          <Li>
            <Link to="/en/wrist-surgery/">Wrist surgery</Link>
          </Li>
          <Li>
            <Link to="/en/techniques/">Techniques performed</Link>
          </Li>
          <Li />
        </Ul>
      </FlexItem>
      <FlexItem>
        <Title
          css={css`
            @media (max-width: 678px) {
              margin-top: 25px;
            }
          `}
        >
          Other topics
        </Title>
        <Ul>
          <Li>
            <Link to="/en/overview/">Overview</Link>
          </Li>
          <Li>
            <Link to="/en/consultation/">Consultation</Link>
          </Li>
          <Li>
            <Link to="/en/clinic/">Clinic</Link>
          </Li>
          <Li>
            <Link to="/en/team/">Team</Link>
          </Li>
          <Li>
            <Link to="/en/contact/">Contact</Link>
          </Li>

          <Li />
        </Ul>
      </FlexItem>
    </FlexWrapper>
    <Mention>
      <ImageFooterDomeInvalides />
      <p>
        Dr. Philippe Roure, orthopedic surgeon in Paris, specialized in surgery
        of the hand and the upper limb. This site is intended to present his
        practice, his medical practices, as well as the information of his
        patients. It does not exempt under any circumstances from a medical
        consultation. For more information, you can{" "}
        <Link
          to="/en/contact/#rdv"
          css={css`
            text-decoration: none;
            font-weight: 600;
            :hover {
              background-color: hsla(193, 5%, 62%, 0.2);
              border-color: hsla(193, 5%, 62%, 0.2);
            }
          `}
        >
          make an appointment
        </Link>
        .
      </p>
      <p
        css={css`
          font-weight: 500;
          letter-spacing: 0.033em;
          margin-top: 14px;
        `}
      >
        Dr Roure - Orthopedic surgeon in Paris © 2025 |{" "}
        <Link
          to="/mentions-legales/"
          css={css`
            border-bottom: none;
            padding: 0.5em 0.15em 0.5em 0.15em;
          `}
        >
          Legal Notice
        </Link>{" "}
        |{" "}
        <Link
          to="/contact/"
          css={css`
            border-bottom: none;
            padding: 0.5em 0.15em 0.5em 0.15em;
          `}
        >
          Contact us
        </Link>{" "}
        |{" "}
        <Link
          to={props.pathFr}
          css={css`
            border-bottom: none;
            padding: 0.5em 0.15em 0.5em 0.15em;
          `}
        >
          <span role="img" aria-label="French version">
            🇫🇷
          </span>
          &nbsp;French version
        </Link>
      </p>
    </Mention>
  </Footer>
)

export default FooterBlock

const Footer = styled.footer`
  background-color: rgba(44, 44, 44, 0.98);
  padding: 2.5em 2.5em;
  a {
    color: white;
  }
  @media (max-width: 678px) {
    padding: 25px 23px 115px;
  }
`

const FlexWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  @media (max-width: 678px) {
    display: block;
  }
`

const FlexItem = styled.div`
  flex: 25%;
  color: white;
  @media (max-width: 678px) {
    flex: 50%;
  }
`

const Title = styled.div`
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  letter-spacing: 0.05em;
  margin: 0 0 0.75em;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    font-size: 1em;
  }
`

const Address = styled.div`
  font-size: 0.88em;
  line-height: 2.273em;
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  font-size: 0.88em;
  margin: 0.35em 0;
  a {
    padding: 0.65em 0;
    text-decoration: none;
  }
`

const Mention = styled.div`
  width: 100%;
  color: white;
  max-width: 1150px;
  margin: 0 auto;
  font-size: 0.86em;
  line-height: 1.65em;
  padding-top: 1em;
  p {
    opacity: 0.8;
  }
`

const A = styled.a`
  border: none;
  text-decoration: none;
  :hover {
    background-color: hsla(193, 5%, 62%, 0.2);
    border-color: hsla(193, 5%, 62%, 0.2);
  }
`
